<template>
  <div class="relative pt-[56px] font-Pretendard">
    <div class="mt-10 mb-12 w-[calc(100vw-32px)] mx-auto tablet:mt-20 tablet:mb-20 tablet:w-[calc(100vw-64px)] pc:w-[90%] pc:max-w-[1080px] pc:mt-[148px] desktop:max-w-7xl">
      <section>
        <div 
          class="h-[22px] font-ClashDisplayMedium text-[19px] font-normal leading-[22px] tracking-normal text-orange-500
          tablet:h-[46px] tablet:text-[36px] tablet:font-medium tablet:leading-[46px] tablet:-tracking-[0.3px]
          pc:h-16 pc:text-[48px] pc:font-normal pc:leading-[64px] pc:tracking-normal"
        >
          Our Mission
        </div>
        <!-- <div class="h-16 text-[40px] font-black leading-[64px] -tracking-[0.67px]"> -->
        <div class="h-16 w-[243px] tablet:h-[133px] tablet:w-[511px] pc:h-[193px] pc:w-[798px]">
          <!-- 세상을 즐겁게!_ -->
          <lottie
            :options="ourMissionLottieOptions"
          />
        </div>
        <div 
          v-if="$screen.breakpoint !== 'mobile'"
          class="text-gray-600 tablet:mt-1 tablet:h-[96px] tablet:text-lg tablet:leading-8 tablet:-tracking-[0.1px]
          pc:mt-4 pc:h-[108px] pc:text-[20px] pc:leading-[36px] pc:-tracking-[0.2px]"
        >
          매일 아침 서울의 출근길에서, 대낮의 LA의 카페에서, 새벽 시간 도쿄의<br>
          침대 맡에서, 전세계 사람들의 일상에 크고 작은 즐거움이 채워질 수 있도록.<br>
          우리는 오늘도, 내일도 즐기고 싶은 재미있는 게임을 만들고 있어요!<br>
        </div>
        <div 
          v-else 
          class="mt-1.5 h-[72px] text-[15px] leading-6 -tracking-[0.11px] text-gray-700"
        >
          전세계 사람들의 일상에 크고 작은 즐거움이<br>
          채워질 수 있도록. 우리는 오늘도, 내일도<br>
          즐기고 싶은 재미있는 게임을 만들고 있어요!<br>
        </div>
      </section>
      <div 
        class="mt-6 flex flex-col flex-1 gap-2.5 tablet:mt-10 tablet:gap-6 pc:mt-14 pc:flex-row pc:max-h-[537px]" 
      >
        <div 
          class="rounded-[12px] w-full aspect-video relative pc:w-[76%] pc:max-w-[954px]" 
          style="background-image:url('https://hr-fileupload.s3.ap-northeast-2.amazonaws.com/2024-08-12/338578c0-5883-11ef-b785-11ed461d41a5/main_000.jpg'); background-size:cover; background-repeat: no-repeat;"
        >
          <div class="relative w-full h-full">
            <iframe
              src="https://player.vimeo.com/video/997562556?autoplay=1&loop=1&Title=0&background=1&autopause=0"
              width="100%"
              height="100%"
              class="absolute inset-0 rounded-[12px] w-full h-full object-cover"
              frameborder="0"
            />
            <router-link 
              to="/games" 
              class="absolute inset-0 z-10" 
            />
          </div>
          <!-- <router-link to="/games">
            <iframe 
              src="https://player.vimeo.com/video/997562556?autoplay=1&loop=1&Title=0&background=1&autopause=0" 
              width="100%" 
              height="100%" 
              class="absolute inset-0 rounded-[12px] w-full h-full object-cover" 
              frameborder="0" 
            />
            <video 
              class="absolute inset-0 rounded-[12px] w-full h-full object-cover" 
              src="https://player.vimeo.com/video/997562556" 
              autoplay 
              loop 
              muted 
              playsinline
              poster="@/assets/our_mission/main_poster.jpg" 
              alt="main video"
            />
            <video 
              class="absolute inset-0 rounded-[12px] w-full h-full object-cover" 
              src="@/assets/our_mission/main.mp4" 
              autoplay 
              loop 
              muted 
              playsinline
              poster="@/assets/our_mission/main_poster.jpg" 
              alt="main video"
            />
          </router-link> -->
        </div>
        <div class="flex flex-col gap-2.5 tablet:flex-row tablet:gap-6 pc:flex-col pc:w-[24%] pc:max-w-[302px] pc:gap-0 pc:justify-between">
          <div class="w-full tablet:w-1/2 pc:w-full transition-transform duration-300 ease-in-out transform pc:hover:rounded-[12px] pc:hover:-translate-y-[20px] pc:hover:shadow-card">
            <a 
              href="https://story.cookapps.com/articles/170" 
            >
              <img
                v-if="$screen.breakpoint === 'pc'" 
                src="@/assets/our_mission/pc_banner_01.png" 
                class="object-contain" 
                alt="쿡앱스, 요리회사 인가요?"
              >
              <img
                v-else-if="$screen.breakpoint === 'mobile'" 
                src="@/assets/our_mission/mb_banner_01.png" 
                class="object-contain" 
                alt="쿡앱스, 요리회사 인가요?"
              >
              <img
                v-else
                src="@/assets/our_mission/tb_banner_01.png" 
                class="object-contain" 
                alt="쿡앱스, 요리회사 인가요?"
              >
            </a>
          </div>
          <div class="w-full tablet:w-1/2 pc:w-full transition-transform duration-300 ease-in-out transform pc:hover:rounded-[12px] pc:hover:-translate-y-[20px] pc:hover:shadow-card">
            <a 
              href="https://story.cookapps.com/articles/186" 
            >
              <img 
                v-if="$screen.breakpoint === 'pc'" 
                src="@/assets/our_mission/pc_banner_02.png" 
                class="object-contain" 
                alt="쿡앱스의 MVP 개발 방식"
              >
              <img 
                v-else-if="$screen.breakpoint === 'mobile'" 
                src="@/assets/our_mission/mb_banner_02.png" 
                class="object-contain" 
                alt="쿡앱스의 MVP 개발 방식"
              >
              <img 
                v-else
                src="@/assets/our_mission/tb_banner_02.png" 
                class="object-contain" 
                alt="쿡앱스의 MVP 개발 방식"
              >
            </a>
          </div>
        </div>
      </div>
      <section 
        class="pt-0 tablet:pt-6 pc:pt-0"
      >
        <div 
          v-for="category in categories" 
          :key="category.id"
          class="mt-10 tablet:mt-14 pc:mt-[88px]"
        >
          <div 
            class="h-7 text-2xl font-bold leading-7 text-gray-800 
              tablet:h-8 tablet:text-[28px] tablet:leading-8 tablet:-tracking-[0.22px]
              pc:h-[41px] pc:text-[34px] pc:leading-[34px] pc:-tracking-[0.34px]"
          >
            {{ category.title }}
          </div>
          <div 
            v-if="category.articleList.length" 
            class="mt-[14px] pc:mt-8 tablet:mt-6"
          >
            <div 
              class="grid pc:grid-cols-3 tablet:gap-x-6 tablet:grid-cols-2 tablet:gap-y-12 mobile:grid-cols-1 mobile:gap-y-10"
            >
              <div 
                v-for="articleData in category.articleList" 
                :key="articleData.id"
              >
                <div class="relative w-full overflow-hidden align-top mb-0">
                  <a 
                    class="group" 
                    :href="`https://story.cookapps.com/articles/${articleData.id}`"
                  >
                    <div class="relative">
                      <div class="overflow-hidden w-full aspect-video rounded-[8px]">
                        <img 
                          v-if="articleData.thumbnailImg"
                          :src="encodeURI(articleData.thumbnailImg)"
                          sizes="pc:410px tablet:340px mobile:343px"
                          class="w-full aspect-video object-cover border-none rounded-[8px] bg-gray-200 transition-transform duration-300 group-hover:scale-110"
                          :alt="articleData.title"
                        >
                        <div
                          v-else 
                          class="w-full aspect-video object-contain border-none rounded-[8px] bg-gray-200 flex items-center justify-center"
                        >
                          <img
                            class="w-[184px] h-10 pc:w-[221px] pc:h-12 transition-transform duration-300 group-hover:scale-110"
                            src="@/assets/our_mission/logo-cookapps-1.png"
                            alt="cookapps logo"
                          >
                        </div>
                      </div>
                    </div>
                    <div 
                      class="font-bold text-gray-800 relative break-keep group-hover:underline
                        mt-3 text-base leading-[1.25] 
                        tablet:text-lg tablet:leading-[1.56]
                        pc:mt-4 pc:text-[21px] pc:leading-[1.43] pc:-tracking-[0.21px]"
                    >
                      <p 
                        class="break-keep max-w-full whitespace-nowrap overflow-hidden text-ellipsis group-hover:underline" 
                      >
                        {{ articleData.title }}
                      </p>
                    </div>
                  </a>
                  <div 
                    class="text-gray-500 break-keep flex mt-1.5 text-xs
                            tablet:text-sm tablet:leading-[1.57]
                            pc:mt-2 pc:text-base pc:leading-[1.5]"
                  >
                    <p 
                      class="w-full overflow-x-hidden text-ellipsis whitespace-nowrap"
                    >
                      {{ articleData.desc }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center pc:mt-16 tablet:mt-10 mobile:mt-8">
              <button 
                class="border border-solid border-gray-200 bg-white hover:bg-gray-50 active:bg-gray-100
                        h-12 px-10 py-3 rounded-[24px]
                        pc:h-16 pc:py-[17px] pc:rounded-5xl" 
                @click="getMoreArticles(category.categoryId)"
              >
                <span 
                  class="font-Pretendard text-center text-gray-600
                        text-lg leading-[1.33] -tracking-[0.1px]
                        pc:text-[24px] pc:leading-[1.25]"
                >
                  소식 더 보기
                </span>
              </button>
            </div>
            <div
              v-if="category.categoryId === 5" 
              class="w-screen -ml-4 h-[122px] mt-12 bg-gray-800 tablet:-ml-8 tablet:mt-14 pc:mt-20 pc:h-[144px] pc:ml-[calc(-50vw+50%)]"
            >
              <router-link to="/careers/list">
                <div class="flex justify-between pc:w-[90%] pc:max-w-[1080px] desktop:max-w-7xl pc:mx-auto">
                  <div class="flex flex-col justify-center ml-4 gap-1 tablet:ml-8 tablet:gap-1.5 pc:ml-0 pc:gap-2">
                    <div class="text-xl font-bold leading-7 text-white tablet:text-3xl pc:text-[36px] pc:leading-[46px] pc:-tracking-[0.3px]">
                      쿡앱스는 지금<br v-if="$screen.breakpoint === 'mobile'"> 게임 제작 전 직군 채용 중!
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-xs font-bold text-orange-500 tablet:text-lg tablet:leading-6 pc:text-xl pc:leading-[26px]">
                        지금 바로 지원하기
                      </div>
                      <img 
                        class="inline-block w-3 h-3 tablet:w-4 tablet:h-4 pc:w-[18px] pc:h-[18px]" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        alt="쿡앱스는 지금 게임 제작 전 직군 채용 중!"
                      >
                    </div>
                  </div>
                  <div 
                    v-if="$screen.breakpoint === 'pc'" 
                    class="w-[324px] h-[144px] object-contain"
                  >
                    <img 
                      src="@/assets/our_mission/pc_banner_03.webp" 
                      width="324" 
                      height="144" 
                    >
                  </div>
                  <div 
                    v-else-if="$screen.breakpoint === 'mobile'" 
                    class="w-[135px] h-[122px] object-contain"
                  >
                    <img 
                      src="@/assets/our_mission/mb_banner_03.webp" 
                      width="135" 
                      height="122" 
                    >
                  </div>
                  <div 
                    v-else 
                    class="w-[274px] h-[122px] object-contain"
                  >
                    <img 
                      src="@/assets/our_mission/tb_banner_03.webp" 
                      width="274" 
                      height="122" 
                    >
                  </div>
                </div>
              </router-link>
            </div>
            <div 
              v-if="category.categoryId === 6"
            >
              <router-link to="/publishing">
                <div 
                  v-if="$screen.breakpoint === 'pc'" 
                  class="w-screen ml-[calc(-50vw+50%)] h-[242px] mt-20 bg-gray-800 bg-cover flex items-center"
                  :style="{ backgroundImage: 'url(' + require('@/assets/our_mission/pc_banner_06.png') + ')' }"
                >
                  <div class="flex flex-col justify-center gap-3 calc-ml-pc">
                    <div class="text-[36px] font-bold leading-[48px] -tracking-[0.3px] text-white">
                      글로벌 게임 제작을 넘어<br>
                      글로벌 퍼블리싱까지
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-xl leading-[26px] font-bold text-orange-500">
                        퍼블리싱 제안하기
                      </div>
                      <img 
                        class="inline-block -pt-1" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        width="18" 
                        height="18" 
                        alt="right arrow icon"
                      >
                    </div>
                  </div>
                </div>
                <div 
                  v-else-if="$screen.breakpoint === 'mobile'" 
                  class="w-screen -ml-4 h-[150px] mt-12 bg-gray-800 bg-cover flex items-center"
                  :style="{ backgroundImage: 'url(' + require('@/assets/our_mission/mb_banner_06.png') + ')' }"
                >
                  <div class="flex flex-col justify-center ml-4 gap-1">
                    <div class="text-xl font-bold leading-7 text-white">
                      글로벌 게임 제작을 넘어<br>
                      글로벌 퍼블리싱까지
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-xs font-bold text-orange-500">
                        퍼블리싱 제안하기
                      </div>
                      <img 
                        class="inline-block" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        width="12" 
                        height="12" 
                        alt="right arrow icon"
                      >
                    </div>
                  </div>
                </div>
                <div 
                  v-else
                  class="w-screen -ml-8 h-[200px] mt-16 bg-gray-800 bg-cover flex items-center"
                  :style="{ backgroundImage: 'url(' + require('@/assets/our_mission/tb_banner_06.png') + ')' }"
                >
                  <div class="flex flex-col justify-center ml-16 gap-1.5">
                    <div class="text-3xl font-bold leading-[37px] text-white">
                      글로벌 게임 제작을 넘어<br>
                      글로벌 퍼블리싱까지
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-lg leading-6 font-bold text-orange-500">
                        퍼블리싱 제안하기
                      </div>
                      <img 
                        class="inline-block" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        width="16" 
                        height="16" 
                        alt="right arrow icon"
                      >
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div 
              v-if="category.categoryId === 1" 
            >
              <router-link to="/games">
                <div 
                  v-if="$screen.breakpoint === 'pc'" 
                  class="w-screen ml-[calc(-50vw+50%)] h-[242px] mt-20 bg-gray-800 bg-cover flex items-center"
                  :style="{ backgroundImage: 'url(' + require('@/assets/our_mission/pc_banner_07.png') + ')' }"
                >
                  <div class="flex flex-col justify-center gap-3 calc-ml-pc">
                    <div class="text-[36px] font-bold leading-[48px] -tracking-[0.3px] text-white">
                      지구 반대편까지 전달되는<br>
                      쿡앱스의 작고 빠른 시도
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-xl leading-[26px] font-bold text-orange-500">
                        쿡앱스 게임 바로가기
                      </div>
                      <img 
                        class="inline-block -pt-1" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        width="18" 
                        height="18" 
                        alt="right arrow icon"
                      >
                    </div>
                  </div>
                </div>
                <div 
                  v-else-if="$screen.breakpoint === 'mobile'" 
                  class="w-screen -ml-4 h-[150px] mt-12 bg-gray-800 bg-cover flex items-center"
                  :style="{ backgroundImage: 'url(' + require('@/assets/our_mission/mb_banner_07.webp') + ')' }"
                >
                  <div class="flex flex-col justify-center ml-4 gap-1">
                    <div class="text-xl font-bold leading-7 text-white">
                      지구 반대편까지 전달되는<br>
                      쿡앱스의 작고 빠른 시도
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-xs font-bold text-orange-500">
                        쿡앱스 게임 바로가기
                      </div>
                      <img 
                        class="inline-block" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        width="12" 
                        height="12" 
                        alt="right arrow icon"
                      >
                    </div>
                  </div>
                </div>
                <div 
                  v-else
                  class="w-screen -ml-8 h-[200px] mt-16 bg-gray-800 bg-cover flex items-center"
                  :style="{ backgroundImage: 'url(' + require('@/assets/our_mission/tb_banner_07.webp') + ')' }"
                >
                  <div class="flex flex-col justify-center ml-16 gap-1.5">
                    <div class="text-3xl font-bold leading-[37px] text-white">
                      지구 반대편까지 전달되는<br>
                      쿡앱스의 작고 빠른 시도
                    </div>
                    <div class="flex gap-1 items-center">
                      <div class="text-lg leading-6 font-bold text-orange-500">
                        쿡앱스 게임 바로가기
                      </div>
                      <img 
                        class="inline-block" 
                        src="@/assets/our_mission/icon-arrow-right.svg" 
                        width="16" 
                        height="16" 
                        alt="right arrow icon"
                      >
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div 
            v-else
            class="pc:mt-[170px] pc:mb-[300px] tablet:mt-[140px] tablet:pb-[93px] mobile:mt-[120px] mobile:pb-[105px]"
          >
            <div class="flex items-center justify-center mx-auto">
              <img 
                v-if="$screen.breakpoint !== 'mobile'" 
                src="@/assets/our_mission/icon-cook-2.svg" 
                width="100"
                height="100" 
                class="object-contain" 
                alt="" 
              >
              <img 
                v-else 
                src="@/assets/our_mission/icon-cook-2.svg" 
                width="72" 
                height="72" 
                class="object-contain" 
                alt="" 
              >
            </div>
            <div class="flex items-center justify-center mx-auto mt-4 tablet:mt-6">
              <span class="font-bold text-gray-300 text-2xl leading-[1.27] tablet:text-3xl ">등록된 스토리가 없습니다.</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { fetchArticleList } from '@/api/cookapps-story';
import Lottie from '../../../../node_modules/vue-lottie/src/lottie.vue';

export default {
  name: 'OurMission',
  components: { 
    'lottie': Lottie,
  },
  data() {
    return {
      articleTotalList: [],
      // gameArticleList: [],
      // techArticleList: [],
      // cultureArticleList: [],
      // peopleArticleList: [],
      categories: [
        { id: 'game', title: '🕹🎯 쿡앱스의 작고 빠른 시도들', categoryId: 5, articleList: [] },
        { id: 'tech', title: '🧪🥼 쿡앱스 연구실', categoryId: 6, articleList: [] },
        { id: 'culture', title: '🙋‍♂️🙋‍♀️ 쿡앱스의 문화', categoryId: 1, articleList: [] },
        { id: 'people', title: '👩‍💻👨‍💻 쿡앱스 피플', categoryId: 3, articleList: [] }
      ],
      articleSliceIdx: this.$screen.breakpoint !== 'mobile' ? 6 : 4,
      ourMissionLottieOptions: {animationData: require('@/assets/our_mission/lottie_our_mission.json')},
    };
  },
  async created() {
    await this.getArticleList();
  },
  methods: {
    async getArticleList() {
      const result = await fetchArticleList();
      this.articleTotalList = result.data.items;
      this.categories.forEach(category => {
        category.articleList = this.articleTotalList
          .filter(item => item.categoryId === category.categoryId)
          .slice(0, this.articleSliceIdx);
      });
    },
    getMoreArticles(categoryId) {
      window.location.href = `https://story.cookapps.com/articles?category=${categoryId}`;
      // window.location.href = `http://localhost:8888/articles?category=${categoryId}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.calc-ml-pc {
  margin-left: calc((100vw - 90%) / 2);

  @media (min-width: 1212px) {
    margin-left: calc((100vw - 1080px) / 2);
  }
  @media (min-width: 1920px) {
    margin-left: calc((100vw - 1280px) / 2);
  }
}
</style>